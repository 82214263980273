export const showWaitPage = async (short_mesg, resolve_timeout) => {
    const loading = useLoadingState();
    resolve_timeout = resolve_timeout || 10;

    if (document.getElementById('wait_page_prefix') != null) {
        if (short_mesg) {
            document.getElementById('wait_page_prefix').innerText = short_mesg;
        } else {
            document.getElementById('wait_page_prefix').innerText = '';
        }    
    }
    
    loading.value = true;
    const promise = new Promise(function(resolve, reject) {
        setTimeout(() => {
            resolve(true);
        }, resolve_timeout)
    });
    return promise;
}