export const setPageMountedState = (val) => {
    let page_mounted_state = usePageMountedState();
    page_mounted_state.value = val;
    if (typeof(window) != 'undefined') {
        if (val) {
            hideWaitPage();
        } else {
            showWaitPage('',10); // Here we do not care about using await.  Calling with await somehow cause the wait page to display twice when the user switch the workspace

            setTimeout(() => {
                hideWaitPage(); // If the user navigate to some page and then use the back button, we did not hide the wait page.
            },100)
        }
    }
}