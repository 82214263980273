export const logFetchAJAX = async(url, payload, headers) => {
  /*
  const acctok = getAccessToken()
  if (false && acctok.length > 0) {
    if (!payload.headers) payload.headers = {}
    payload.headers["Authorization"] = "Bearer " + acctok;
  }
  */
  const d = Date.now();
  const requestId = d + "_" + Math.random().toString(36).substring(2,10)
  const prefix = "Request ID [" + requestId + "]"
  let response;
  const agent_message_state = useAgentMessageState();

  /*
  Inside this method, I attempted to use useFetch for everything and for the most part it seems to work fine,
  but later on I notice an issue with the listTask composable.  It thinks that the response from useFetch (result.data.value)
  is null, and thus got an error 'cannot read property of a null object', so I went back to use logFetchAJAX as we were using 
  logFetchAJAX previously.
  */
  if (typeof(payload) == 'undefined') {
    payload = {};
  }
  if (! payload.hasOwnProperty('method')) {
    payload['method'] = 'GET';
  }

  let csrf = '';
  if (typeof(window) != 'undefined') {
    csrf = window['__NUXT__']?.data?.__dk__?.csrf || ''
  }
  if (payload.hasOwnProperty('body') && (csrf != '') && (payload['method'] != 'DELETE')) {
    payload['body']['_csrf_token'] = csrf;
  }

  console.log(prefix, "URL: ", url, "Payload: ", payload);

  let proxy_not_active_message = "Proxy session not active";
  let bad_request_message = "Bad Request";

  let useAjax = false;
  if (process.server) {
    useAjax = false;
  } else {
    if (canViewExperimentalFeatures()) {
      useAjax = true;
    } else {
      useAjax = true;
    }
  }
  if (useAjax) {
    const promise = new Promise(function(resolve, reject) {
      var xhttp = new XMLHttpRequest();
      var myresp = {};
      xhttp.onreadystatechange = function() {
  
        if (xhttp.readyState === XMLHttpRequest.DONE) {
          const status = xhttp.status;
          if (status === 0 || (status >= 200 && status < 400)) {
            // The request has been completed successfully
            try {
              var respObj = JSON.parse(this.responseText);
              console.log(prefix, `Response (Time: ${Date.now() - d}ms), status: ${status} `, respObj);
              myresp = respObj;
              // When we have the Chrome Devtool console opened, something is printing 'XHR finished loading'
              // On the CLI sessions listing page, when we calls loadCLISessions, it calls fetchCLISessions,
              // and we used 'await' everywhere, but we see the 'XHR finished loading' message after we were 
              // able to obtain the result.  This is confusing when we look at the console.  And it may 
              // suggest that the AJAX connection is not yet quite done / closed / released.  So here, we use 
              // a small timeout to ensure that our code is only continue to execute after the browser had
              // a chance to do appropriate thing with the connection.
              setTimeout(() => {
                resolve(myresp);
              }, 5);
            } catch (e) {
              console.log(prefix, `Response (Time: ${Date.now() - d}ms), status: ${status} `);
              hideWaitPage();
              let is_real_error = true;
              if ((typeof(this.responseText) != 'undefined') && (this.responseText != null) && (this.responseText.toLowerCase().includes('doctype'))) {
                // This looks to be our signout request, which sends back the full HTML page, and cannot be parsed using JSON.parse, so this is 
                // not a real error.
                is_real_error = false;
              }
              if (url.includes('logout')) {
                is_real_error = false;
              }
              if ((url.includes('check_session_state')) && (status === 0)) {
                // If this is the check_session_state request, and the status is 0, we will consider this as not a real error
                // (do not display the alert), and let the timer loop continue to run
                is_real_error = false;
              }

              if (status === 0) {
                // This happens when the user clicks on a link or navigate to another page when the current page is not
                // yet completely loaded, or when there is a long running AJAX call to provision a proxy that we don't 
                // necessarily care whether it finish or not.
                is_real_error = false;
              }
              
              if (is_real_error) {
                console.log(`ERROR.  Status: ${status}, RESPONSE TEXT:`, this.responseText)
                dkAlert("An error happened.  Please try again.");  
                reject(myresp);
                throw `Error ${status}: ${this.responseText}`;    
              } else {
                resolve(myresp);
              }
            }
          } else {
            if (! xhttp.responseText.includes('JobNotFound:StopFailed')) {
              console.log(prefix, `Response (Time: ${Date.now() - d}ms), status: ${status} `, this.responseText);
            }
            hideWaitPage();
            let message = ''
            if (status == 500) {
              message = 'Internal Server Error';
            } else if (status == 501) {
              message = 'Not Implemented';
            } else if (status == 502) {
              message = 'Bad Gateway';
            } else if (status == 503) {
              message = 'Gateway Timeout'
            } else if (typeof(xhttp.responseText) != 'undefined'){
              if (xhttp.responseText.toLowerCase().includes(proxy_not_active_message.toLowerCase())) {
                // If the actual responseText contains other information, do not show it (do not display extra info (useless to the user), like token)
                message = proxy_not_active_message; 
                if (typeof(window) != 'undefined') {
                  let org = localStorage.getItem('org');
                  if (org.toLowerCase().startsWith('trial')) {
                    message = "Proxy session not yet active.  Please try again in a few minutes."
                  }
                }
                agent_message_state.value = "";
              } else if (xhttp.responseText.toLowerCase().includes(bad_request_message.toLowerCase())) {
                message = bad_request_message;
              } else {
                message = xhttp.responseText
              }
            }
            if ((status == '400') && (message.includes('JobNotFound:StopFailed'))) {
              resolve("Job stopped");
            } else {
              dkAlert(`Error ${status}: ${message}`);  
              reject(myresp);
              throw `Error ${status}: ${message}`;  
            }
          }
        }
      }
      xhttp.timeout = 300000;
      xhttp.open(payload['method'], url, true);

      // xhttp.setRequestHeader('accept', 'application/json');
      if (! ['GET', 'DELETE'].includes(payload['method'].toUpperCase())) {
        xhttp.setRequestHeader('content-type', 'application/json');
      }

      if ((csrf != '') && (['DELETE', 'PUT'].includes(payload['method'].toUpperCase()))) {
        xhttp.setRequestHeader('CSRF', csrf);
      }

      if (headers?.hasOwnProperty('Authorization')) {
        let v = headers['Authorization'];
        xhttp.setRequestHeader('Authorization', v);
      }

      const runtimeConfig = useRuntimeConfig();
      let token = runtimeConfig.public.cors_jwt_token;
      const settingStore = useSettingsStore();
      let settings = settingStore.getSetting;
      if (
        (settings.hasOwnProperty('admin_settings')) && 
        (settings.admin_settings.hasOwnProperty('flag_enable_dagknows_community')) &&
        (settings.admin_settings.flag_enable_dagknows_community) &&
        (settings.hasOwnProperty('dagknows_community_credentials')) &&
        (settings.dagknows_community_credentials.hasOwnProperty('token'))
      ) {
        let community_url = getCommunityURL();
        if ((community_url != '') && (url.startsWith(community_url))) {
          token = settings.dagknows_community_credentials['token'];
        }
      }
      
      if ((token != null) && (token != '')) {
        xhttp.setRequestHeader('authorization', `Bearer ${token}`);
      }
  
      console.log(`logFetchAJAX called for ${url}`, payload)
      if (payload.hasOwnProperty('body')) {
        xhttp.send(JSON.stringify(payload['body']));
      } else {
        xhttp.send();
      }
    });
    return promise;   
  }

  let key = "";
  if (url.indexOf('/api/') > -1) {
    // If we do not provide a key, it will be automatically generated for us, based on the URL.
    // However, our backend and our frontend typically use different URLs for the same resources.
    // For example, our frontend typically uses https://localhost/api/tasks but our backend may 
    // use http://req-router:8080/api/tasks.  Because of this, we may not be using Nuxt's data 
    // caching, and may hit the same resource twice, once during the SSR phase, and then once 
    // during client side hydration.  To take advantage of Nuxt's data caching, and avoid hitting 
    // the same resource twice, we try to generate our own key by removing everything before the 
    // path.

    // Because the "global search" API is not yet ready from the backend, the frontend is doing 
    // two separate searches, once against the local backend taskservice, and once against the 
    // community's taskservice, and the merge the result together.  Special consideration is required 
    // here to support searching from community.  We have to compare the URL against the community URL.
    // If the URL matches the community URL, we don't remove the parts before the path.  Here the path 
    // would be the same, but the hostname portion of the URL would be different.  By not removing the 
    // parts before the path, we are forcing the keys to be different.  If we don't force the keys to 
    // be different somehow, we can see a strange behavior.  For example, I was searching for a phrase,
    // and my local system does not have any task matching that phrase, but the community server has 
    // one task matching that phrase, but the frontend display two tasks, and both are marked as coming 
    // from community.  The reason was that I was using the same key, so Nuxt's useFetch return 1 task 
    // when I query the local taskservice even though my local taskservice does not have any task 
    // matching the particular phrase that I was searching for.  To avoid this, we have to make sure 
    // that they keys are different.

    // Care should be taken here.  Because getCommunityURL depends on "getSettings", and "getSettings" depends 
    // on this function.  Therefore, we may run into infinite loop if we change the condition of this if statement 
    // that his block is in.  Currently the URL for getSettings is /getSettings.  If we change it to /api/getSettings 
    // then it may result in an infinite loop when this function is called.  If we change the condition for the if 
    // statement that this code is in to  (url.indexOf('/') > -1), then this may also result in an infinite loop 
    // when this function is called.

    let community_url = getCommunityURL();
    if ((url.toLowerCase().startsWith(community_url.toLowerCase())) && (community_url != '')) {
      key = url;
    } else {
      key = url.substring(url.indexOf('/api/')); // key now hold the remainder of the URL (everything after the hostname)
    }

    /*
    if (process.client) {
      let community_url = getCommunityURL();
      if ((url.toLowerCase().startsWith(community_url.toLowerCase())) && (community_url != '')) {
        key = url;
      } else {
        key = url.substring(url.indexOf('/api/')); // key now hold the remainder of the URL (everything after the hostname)
      }
    } else {
      key = url.substring(url.indexOf('/api/')); // key now hold the remainder of the URL (everything after the hostname)
    }
    */

    payload.key = `${payload.method}_${key}_${JSON.stringify(payload).length}`;
    //console.log(`payload.key: ${payload.key}`)
  }

  if (typeof(window) == 'undefined') {
    payload['credentials'] = 'same-origin';
    payload['headers'] = useRequestHeaders(['cookie'])
  }

  //payload['timeout'] = 5000; // Milliseconds
  //payload['retry'] = 1;
  //payload['retryDelay'] = 500; // Milliseconds
  //payload['retryStatusCodes'] = [408, 409, 425, 429, 500, 502, 503, 504]

  // const { data, pending, error, refresh } = useFetch(...);
  const result = await useFetch(url, payload);
  response = result.data.value;
  if ((response == null) && (! url.includes('logout'))) {
    // Why does useFetch sometimes return null, and not making request to the server?  When that happens try again.
    // However, we cannot use clearNuxtData here.  It give another error, just as sporadic as the original issue of
    // useFetch returning null.  Our only other alternative option is to use logFetchAJAX:

    // clearNuxtData();
    // result = await useFetch(url, payload);
    // response = result.data.value;

    // We can further investigate various properties provided by the result variable.
    // These other properties includes: pending, error, status, etc.
    console.log(`RESPONSE WAS NULL.  requestId: ${requestId}, RESULT:`, result);
    try {
      response = await $fetch(url, payload);
    } catch (e) {
      if ((e.message.includes('401 UNAUTHORIZED')) || (e.message.includes('404 NOT FOUND'))) {
        throw(e)
      } else {
        console.log("Our logFetch composable received an error: ", e)
        if (typeof(window) != 'undefined') {
          hideWaitPage();
          dkAlert("An error happened.  Please try again.");  
        }
      }
    }
  }

  if (process.client) {
    console.log(prefix, `Response (Time: ${Date.now() - d}ms): `, response)
  } else {
    console.log(prefix, `Response (Time: ${Date.now() - d}ms): `)
  }
  return response;
}
