export const getSettings = async () => {

    if (! isAuthenticated()) {
      return {};
    }

    const result = await logFetchAJAX(getUrl('/getSettings'), {
        method: 'POST',
        body: {}
    });
    if ((result) && (! result.hasOwnProperty('admin_settings'))) {
        result['admin_settings'] = {}
    }
    return result;

    /*
    var xhttp = new XMLHttpRequest();
    var myresp = {};
    xhttp.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
          var respObj = JSON.parse(this.responseText);
          console.log("Received this response object: ", respObj);
          myresp = respObj;
        } else if (this.readyState == 4 && this.status != 200) {          
            console.log("ERROR! getSettings called failed")          
        } else {
          console.log("current request state: ", this.readyState);
        }
      };    
    xhttp.open("POST", '/getSettings', false);
    xhttp.send(JSON.stringify({}));
    console.log("Sending this response object: ", myresp);
    return myresp;
    */
}
