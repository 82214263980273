export const getCurrentUserInfo = async () => {    
    const response = await logFetchAJAX(getUrl(`/get_user_info`), {
      method: "GET"
    });

    let user_info = {}
    if (response.hasOwnProperty('user_info')) {
        user_info = response['user_info'];
    }
    return user_info;
}