export const isAuthenticated = () => {
    const userInfoStore = useUserInfoStore()
    if (userInfoStore.getUserInfo.hasOwnProperty('role'))  {
        return true;
    } else {
        return false;
    }
    //let role = userInfoStore.getUserInfo['role'];

    /*
    if (typeof(window) != 'undefined') {
        let fromStorage = localStorage.getItem("isAuthenticated");
        if (fromStorage == null) {
            return false;
        } else if (fromStorage == "1") {
            return true;
        } else {
            return false;
        }    
    } else {
        const my_cookie = useCookie('MY_V_AUTH', {
            'sameSite': 'lax'
        });
        if (my_cookie.value == 1) {
            return true;
        } else {
            return false;
        }
    }
    */
}

