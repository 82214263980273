<template>
  <div :class="{ 'loading': true, 'd-none': !loading.valueOf() }">
    <div id="id_saving" class="saving">
      <div id="wait_page_prefix" class="d-inline"></div>
      <span class="dot"></span>
      <span class="dot"></span>
      <span class="dot"></span>
      <span class="dot"></span>
    </div>
  </div>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>
let community_url = '';
let settingStore = null;
let settings = null;

if (process.client) {
  settingStore = useSettingsStore();
  settings = settingStore.getSetting;
  if ((settings == null) || (Object.keys(settings).length == 0)) {
    await settingStore.setSetting(null);
    settings = settingStore.getSetting;
  }
  community_url = getCommunityURL();
}

// Note: We do not store the token inside localStorage anymore.  The comment below is therefore 
// obsolete but kept here just in case we need it later.  We should not store the token inside 
// localStorage because the browser does not offer any security protection for localStorage.
// For now, we use the CORS_JWT_TOKEN environment variable to support remote developers.
// See our documentation page in Confluence for more information.

// This ofetch stuff is for retrieving the token from localStorage and adding it to the headers
// of the request.  The logic here seems correct.  We still need to fetch the token, and save it
// to localStorage after the user log into the application, and I will get to it soon.  Because
// the front-end is on the same domain as the back-end, and we let the back-end sends the
// session cookie to the browser, using this token is technically not required.  It may be handy
// if we decide to use one domain for the front-end, and one domain for the back-end.  We may
// need to move this "interceptor" into a separate composable if we ever get too much stuff in
// here.  Search the net for "nuxt 3 interceptor".
import { ofetch } from "ofetch";
const opts = {
  async onRequest({ request, options }) {
    if (typeof options.headers == "undefined") {
      options.headers = {};
    }
    // I probably do not need to add the authentication bearer token because the client side is already sending the cookie.
    // But if we do not send the authentication bearer token, the /searchTasks endpoint seems to think
    // that the user is anonymous, so not sending the token will not work for some scenarios for some reason.

    // We should always send the Bearer token.  We may need to send the Bearer token for SSR.  I think by default,
    // Nuxt will attempt to pass all the headers including cookies to the destination server (req_router), but I have 
    // seen that failed in the past.  I am not entirely sure if we need to send the Bearer tooken for SSR, but for 
    // our remote developers, we need to send the Bearer token because our remote developers are doing cross domains because 
    // they do not have a full setup.

    // The remote server should ignore the token if it is not valid (not exist).

    await addAuthHeaders(options.headers, request, settings, community_url);
  },
  
  async onResponse({ request, options, response }) {
  },

  async onRequestError({ request, options, error }) {
    console.log('[fetch request error]', request, options, error);
    if (typeof(window) != 'undefined') {
      hideWaitPage();
      showAlert('', {
        'is_request_error': true,
        'request': request, 
        'options': options,
        'error': error
      });
      //throw new Error('An HTTP request error occurred, and logged.  See above.');
      throw error;
    }
  },

  async onResponseError({ request, response, options }) {
    console.log('[fetch response error]', request, options, response.status, response.body);
    if (typeof(window) != 'undefined') {
      hideWaitPage(); // We want to hide the wait page before throwing an error, because that will stop JavaScript execution and the wait page will never hide, and the user has to reload the page
      let http_error_code = response.status;
      var suppress_throw = false;
      var suppress_alert = false;
      if (http_error_code == 404) {
        if ((window.location.href.search(/\/tasks\//) > 0) || (window.location.href.search(/\/deleted-tasks\//) > 0)) {
          suppress_throw = true;
          suppress_alert = true;
        }
        if (window.location.href.search(/\/tasks\//) > 0) {
          let task_id_from_href = window.location.href.replace(/.*\/tasks\//,'').replace(/[\/\?].*/,'');
          let task_id_from_request = request.replace(/.*\/tasks\//,'').replace(/[\/\?].*/,'');
          if (task_id_from_href == task_id_from_request) {
            navigateTo(`/deleted-tasks/${task_id_from_href}`);
            return;
          }
        }
      } else if (http_error_code == 401) {
        if (request.includes('/messages')) {
          suppress_throw = true;
        }

      }


      if (! suppress_alert) {
        showAlert('', {
          'is_response_error': true,
          'request': request, 
          'response': response,
          'options': options
        });
      }

      if (! suppress_throw) {
        throw new Error('An HTTP response error occurred, and logged.  See above.');
      }
    }
  }
};
globalThis.$fetch = ofetch.create(opts);

const nuxtApp = useNuxtApp();
const loading = useLoadingState();
/*
We are explicitly calling showWaitPage and hideWaitPage by calling setPageMountedState from the vLoginRedirectHelper global middleware so 
we should not need this here.

nuxtApp.hook("page:start", () => {
  loading.value = true;
});
nuxtApp.hook("page:finish", () => {
  loading.value = false;
});
*/
</script>

<style scoped>
.error-message-container {
  margin-top: 20px;
  text-align: center;
}
</style>
