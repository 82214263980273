import { default as change_45password2prnjSjuEOMeta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/change-password.vue?macro=true";
import { default as _91id_93m1MKLmRSK3Meta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/cli-sessions/[id].vue?macro=true";
import { default as _91id_93th0jk9knZdMeta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/deleted-tasks/[id].vue?macro=true";
import { default as edit_45user_45profilepj7Eqo1MSwMeta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/edit-user-profile.vue?macro=true";
import { default as indexFFDPer4iJzMeta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/index.vue?macro=true";
import { default as my_45draftsmSUwHzVhxjMeta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/my-drafts.vue?macro=true";
import { default as my_45runbooksa2ycO9gBzHMeta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/my-runbooks.vue?macro=true";
import { default as downloadHrZsmLxanBMeta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/n/download.vue?macro=true";
import { default as interactive_45demoyzDYE8HZPmMeta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/n/interactive-demo.vue?macro=true";
import { default as jobs6SxLOcvADGMeta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/n/jobs.vue?macro=true";
import { default as messagervJkJuwy8cMeta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/n/message.vue?macro=true";
import { default as readmeEYibFgEgNUMeta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/n/readme.vue?macro=true";
import { default as setupMFAGosUCQ4c9uMeta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/n/setupMFA.vue?macro=true";
import { default as signupnIT8OlLTtcMeta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/n/signup.vue?macro=true";
import { default as user_45managementglfaqPXy8rMeta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/n/user-management.vue?macro=true";
import { default as user_45preferencesQNydJoYTS9Meta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/n/user-preferences.vue?macro=true";
import { default as verifyMFAQdF4OAnTqtMeta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/n/verifyMFA.vue?macro=true";
import { default as privacy_45policyqLEoJVkH9NMeta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/privacy-policy.vue?macro=true";
import { default as shared_45with_45me9raOcUE4juMeta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/shared-with-me.vue?macro=true";
import { default as task_45create_45aiDhdTyShANaMeta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/task-create-ai.vue?macro=true";
import { default as task_45createvXfufzaRNbMeta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/task-create.vue?macro=true";
import { default as _91id_93wqr069jGyOMeta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/tasks/[id].vue?macro=true";
import { default as terms_45of_45useW2TYhImhXsMeta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/terms-of-use.vue?macro=true";
import { default as testTY571F624SMeta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/test.vue?macro=true";
import { default as vLoginRedirectBX2klbR6oNMeta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/vLoginRedirect.vue?macro=true";
import { default as vintegrationsnm6gtFcLJqMeta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/vintegrations.vue?macro=true";
import { default as vlogin19QLRIaBhQMeta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/vlogin.vue?macro=true";
import { default as vlogoutyCzn0tXYIsMeta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/vlogout.vue?macro=true";
import { default as vsettings9JYu5DBlQ5Meta } from "/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/vsettings.vue?macro=true";
export default [
  {
    name: change_45password2prnjSjuEOMeta?.name ?? "change-password",
    path: change_45password2prnjSjuEOMeta?.path ?? "/change-password",
    meta: change_45password2prnjSjuEOMeta || {},
    alias: change_45password2prnjSjuEOMeta?.alias || [],
    redirect: change_45password2prnjSjuEOMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/change-password.vue").then(m => m.default || m)
  },
  {
    name: _91id_93m1MKLmRSK3Meta?.name ?? "cli-sessions-id",
    path: _91id_93m1MKLmRSK3Meta?.path ?? "/cli-sessions/:id()",
    meta: _91id_93m1MKLmRSK3Meta || {},
    alias: _91id_93m1MKLmRSK3Meta?.alias || [],
    redirect: _91id_93m1MKLmRSK3Meta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/cli-sessions/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93th0jk9knZdMeta?.name ?? "deleted-tasks-id",
    path: _91id_93th0jk9knZdMeta?.path ?? "/deleted-tasks/:id()",
    meta: _91id_93th0jk9knZdMeta || {},
    alias: _91id_93th0jk9knZdMeta?.alias || [],
    redirect: _91id_93th0jk9knZdMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/deleted-tasks/[id].vue").then(m => m.default || m)
  },
  {
    name: edit_45user_45profilepj7Eqo1MSwMeta?.name ?? "edit-user-profile",
    path: edit_45user_45profilepj7Eqo1MSwMeta?.path ?? "/edit-user-profile",
    meta: edit_45user_45profilepj7Eqo1MSwMeta || {},
    alias: edit_45user_45profilepj7Eqo1MSwMeta?.alias || [],
    redirect: edit_45user_45profilepj7Eqo1MSwMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/edit-user-profile.vue").then(m => m.default || m)
  },
  {
    name: indexFFDPer4iJzMeta?.name ?? "index",
    path: indexFFDPer4iJzMeta?.path ?? "/",
    meta: indexFFDPer4iJzMeta || {},
    alias: indexFFDPer4iJzMeta?.alias || [],
    redirect: indexFFDPer4iJzMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: my_45draftsmSUwHzVhxjMeta?.name ?? "my-drafts",
    path: my_45draftsmSUwHzVhxjMeta?.path ?? "/my-drafts",
    meta: my_45draftsmSUwHzVhxjMeta || {},
    alias: my_45draftsmSUwHzVhxjMeta?.alias || [],
    redirect: my_45draftsmSUwHzVhxjMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/my-drafts.vue").then(m => m.default || m)
  },
  {
    name: my_45runbooksa2ycO9gBzHMeta?.name ?? "my-runbooks",
    path: my_45runbooksa2ycO9gBzHMeta?.path ?? "/my-runbooks",
    meta: my_45runbooksa2ycO9gBzHMeta || {},
    alias: my_45runbooksa2ycO9gBzHMeta?.alias || [],
    redirect: my_45runbooksa2ycO9gBzHMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/my-runbooks.vue").then(m => m.default || m)
  },
  {
    name: downloadHrZsmLxanBMeta?.name ?? "n-download",
    path: downloadHrZsmLxanBMeta?.path ?? "/n/download",
    meta: downloadHrZsmLxanBMeta || {},
    alias: downloadHrZsmLxanBMeta?.alias || [],
    redirect: downloadHrZsmLxanBMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/n/download.vue").then(m => m.default || m)
  },
  {
    name: interactive_45demoyzDYE8HZPmMeta?.name ?? "n-interactive-demo",
    path: interactive_45demoyzDYE8HZPmMeta?.path ?? "/n/interactive-demo",
    meta: interactive_45demoyzDYE8HZPmMeta || {},
    alias: interactive_45demoyzDYE8HZPmMeta?.alias || [],
    redirect: interactive_45demoyzDYE8HZPmMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/n/interactive-demo.vue").then(m => m.default || m)
  },
  {
    name: jobs6SxLOcvADGMeta?.name ?? "n-jobs",
    path: jobs6SxLOcvADGMeta?.path ?? "/n/jobs",
    meta: jobs6SxLOcvADGMeta || {},
    alias: jobs6SxLOcvADGMeta?.alias || [],
    redirect: jobs6SxLOcvADGMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/n/jobs.vue").then(m => m.default || m)
  },
  {
    name: messagervJkJuwy8cMeta?.name ?? "n-message",
    path: messagervJkJuwy8cMeta?.path ?? "/n/message",
    meta: messagervJkJuwy8cMeta || {},
    alias: messagervJkJuwy8cMeta?.alias || [],
    redirect: messagervJkJuwy8cMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/n/message.vue").then(m => m.default || m)
  },
  {
    name: readmeEYibFgEgNUMeta?.name ?? "n-readme",
    path: readmeEYibFgEgNUMeta?.path ?? "/n/readme",
    meta: readmeEYibFgEgNUMeta || {},
    alias: readmeEYibFgEgNUMeta?.alias || [],
    redirect: readmeEYibFgEgNUMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/n/readme.vue").then(m => m.default || m)
  },
  {
    name: setupMFAGosUCQ4c9uMeta?.name ?? "n-setupMFA",
    path: setupMFAGosUCQ4c9uMeta?.path ?? "/n/setupMFA",
    meta: setupMFAGosUCQ4c9uMeta || {},
    alias: setupMFAGosUCQ4c9uMeta?.alias || [],
    redirect: setupMFAGosUCQ4c9uMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/n/setupMFA.vue").then(m => m.default || m)
  },
  {
    name: signupnIT8OlLTtcMeta?.name ?? "n-signup",
    path: signupnIT8OlLTtcMeta?.path ?? "/n/signup",
    meta: signupnIT8OlLTtcMeta || {},
    alias: signupnIT8OlLTtcMeta?.alias || [],
    redirect: signupnIT8OlLTtcMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/n/signup.vue").then(m => m.default || m)
  },
  {
    name: user_45managementglfaqPXy8rMeta?.name ?? "n-user-management",
    path: user_45managementglfaqPXy8rMeta?.path ?? "/n/user-management",
    meta: user_45managementglfaqPXy8rMeta || {},
    alias: user_45managementglfaqPXy8rMeta?.alias || [],
    redirect: user_45managementglfaqPXy8rMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/n/user-management.vue").then(m => m.default || m)
  },
  {
    name: user_45preferencesQNydJoYTS9Meta?.name ?? "n-user-preferences",
    path: user_45preferencesQNydJoYTS9Meta?.path ?? "/n/user-preferences",
    meta: user_45preferencesQNydJoYTS9Meta || {},
    alias: user_45preferencesQNydJoYTS9Meta?.alias || [],
    redirect: user_45preferencesQNydJoYTS9Meta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/n/user-preferences.vue").then(m => m.default || m)
  },
  {
    name: verifyMFAQdF4OAnTqtMeta?.name ?? "n-verifyMFA",
    path: verifyMFAQdF4OAnTqtMeta?.path ?? "/n/verifyMFA",
    meta: verifyMFAQdF4OAnTqtMeta || {},
    alias: verifyMFAQdF4OAnTqtMeta?.alias || [],
    redirect: verifyMFAQdF4OAnTqtMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/n/verifyMFA.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyqLEoJVkH9NMeta?.name ?? "privacy-policy",
    path: privacy_45policyqLEoJVkH9NMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyqLEoJVkH9NMeta || {},
    alias: privacy_45policyqLEoJVkH9NMeta?.alias || [],
    redirect: privacy_45policyqLEoJVkH9NMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: shared_45with_45me9raOcUE4juMeta?.name ?? "shared-with-me",
    path: shared_45with_45me9raOcUE4juMeta?.path ?? "/shared-with-me",
    meta: shared_45with_45me9raOcUE4juMeta || {},
    alias: shared_45with_45me9raOcUE4juMeta?.alias || [],
    redirect: shared_45with_45me9raOcUE4juMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/shared-with-me.vue").then(m => m.default || m)
  },
  {
    name: task_45create_45aiDhdTyShANaMeta?.name ?? "task-create-ai",
    path: task_45create_45aiDhdTyShANaMeta?.path ?? "/task-create-ai",
    meta: task_45create_45aiDhdTyShANaMeta || {},
    alias: task_45create_45aiDhdTyShANaMeta?.alias || [],
    redirect: task_45create_45aiDhdTyShANaMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/task-create-ai.vue").then(m => m.default || m)
  },
  {
    name: task_45createvXfufzaRNbMeta?.name ?? "task-create",
    path: task_45createvXfufzaRNbMeta?.path ?? "/task-create",
    meta: task_45createvXfufzaRNbMeta || {},
    alias: task_45createvXfufzaRNbMeta?.alias || [],
    redirect: task_45createvXfufzaRNbMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/task-create.vue").then(m => m.default || m)
  },
  {
    name: _91id_93wqr069jGyOMeta?.name ?? "tasks-id",
    path: _91id_93wqr069jGyOMeta?.path ?? "/tasks/:id()",
    meta: _91id_93wqr069jGyOMeta || {},
    alias: _91id_93wqr069jGyOMeta?.alias || [],
    redirect: _91id_93wqr069jGyOMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/tasks/[id].vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45useW2TYhImhXsMeta?.name ?? "terms-of-use",
    path: terms_45of_45useW2TYhImhXsMeta?.path ?? "/terms-of-use",
    meta: terms_45of_45useW2TYhImhXsMeta || {},
    alias: terms_45of_45useW2TYhImhXsMeta?.alias || [],
    redirect: terms_45of_45useW2TYhImhXsMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: testTY571F624SMeta?.name ?? "test",
    path: testTY571F624SMeta?.path ?? "/test",
    meta: testTY571F624SMeta || {},
    alias: testTY571F624SMeta?.alias || [],
    redirect: testTY571F624SMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/test.vue").then(m => m.default || m)
  },
  {
    name: vLoginRedirectBX2klbR6oNMeta?.name ?? "vLoginRedirect",
    path: vLoginRedirectBX2klbR6oNMeta?.path ?? "/vLoginRedirect",
    meta: vLoginRedirectBX2klbR6oNMeta || {},
    alias: vLoginRedirectBX2klbR6oNMeta?.alias || [],
    redirect: vLoginRedirectBX2klbR6oNMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/vLoginRedirect.vue").then(m => m.default || m)
  },
  {
    name: vintegrationsnm6gtFcLJqMeta?.name ?? "vintegrations",
    path: vintegrationsnm6gtFcLJqMeta?.path ?? "/vintegrations",
    meta: vintegrationsnm6gtFcLJqMeta || {},
    alias: vintegrationsnm6gtFcLJqMeta?.alias || [],
    redirect: vintegrationsnm6gtFcLJqMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/vintegrations.vue").then(m => m.default || m)
  },
  {
    name: vlogin19QLRIaBhQMeta?.name ?? "vlogin",
    path: vlogin19QLRIaBhQMeta?.path ?? "/vlogin",
    meta: vlogin19QLRIaBhQMeta || {},
    alias: vlogin19QLRIaBhQMeta?.alias || [],
    redirect: vlogin19QLRIaBhQMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/vlogin.vue").then(m => m.default || m)
  },
  {
    name: vlogoutyCzn0tXYIsMeta?.name ?? "vlogout",
    path: vlogoutyCzn0tXYIsMeta?.path ?? "/vlogout",
    meta: vlogoutyCzn0tXYIsMeta || {},
    alias: vlogoutyCzn0tXYIsMeta?.alias || [],
    redirect: vlogoutyCzn0tXYIsMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/vlogout.vue").then(m => m.default || m)
  },
  {
    name: vsettings9JYu5DBlQ5Meta?.name ?? "vsettings",
    path: vsettings9JYu5DBlQ5Meta?.path ?? "/vsettings",
    meta: vsettings9JYu5DBlQ5Meta || {},
    alias: vsettings9JYu5DBlQ5Meta?.alias || [],
    redirect: vsettings9JYu5DBlQ5Meta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/dagknows-nuxt/dagknows_nuxt/pages/vsettings.vue").then(m => m.default || m)
  }
]