export const getUrl = (path) => {
  if (path.toLowerCase().startsWith('http')) {
    return path;
  }
  const runtimeConfig = useRuntimeConfig();
  let limited_setup = undefined;
  if (typeof window === "undefined") {
    limited_setup = runtimeConfig.limited_setup;
  } else {
    limited_setup = runtimeConfig.public.limited_setup;
  }

  if ((typeof(limited_setup) == 'undefined') || (limited_setup == '')) {
    limited_setup = false;
  } else {
    limited_setup = true;
  }

  if (limited_setup  === true) {
    var base_url = "";
    if (typeof window === "undefined") {
      // base_url = "http://taskservice:2235";
      base_url = runtimeConfig.server_side_base_url;      
    } else {
      base_url = runtimeConfig.public.client_side_base_url
    }
    if (!(path).startsWith("/")) {
      path = "/" + path
    }
    return base_url + path  

  } else {
    var base_url = "";
    if (typeof window === "undefined") {
      // base_url = "http://taskservice:2235";
      base_url = "http://req-router:8888";
      
    } else {
      let loc = window.location;
      base_url = loc.protocol + '//' + loc.host;
    }
    if (!(path).startsWith("/")) {
      path = "/" + path
    }
    return base_url + path  
  }

}
