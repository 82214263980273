export const useSettingsStore = defineStore("SettingsStore", {
  state: () => ({
    settings: {},
  }),
  getters: {
    getSetting() {
      return this.settings;
    },
  },
  actions: {
    async setSetting(data) {
      if (data === null) {
        try{
          this.settings = await getSettings();
        }
        catch(e){
          console.error("setSetting failed==========>", e)
          this.settings = {};
        }
      } else {
        this.settings = data
      }
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSettingsStore, import.meta.hot));
}
