export const getEnv = async () => {
    /*
    Note:
    On a page load, this function is being called multiple times.  For example, for the task-create page, this function 
    was being called once in layout/default.value, once in SearchRunbookTextbox.vue, and once in CreateEditViewTask.vue.
    
    In this function, we use the useEnvState composable to reduce the number of requests.  On the client side, this seems 
    to work fine (I think it only make one request to the server).  I think, on the server side, the same thing happens 
    (this function is called 3 times, but it only hit the /envs endpoint once).  Need to confirm this later.

    If this function is being called from CreateEditViewTask, for some reason, we face a strange JavaScript error if we 
    click on the "Create Runbook" button, then click on the back button, and then click on the forward button.  The 
    JavaScript error: 

    Uncaught (in promise) TypeError: Cannot read properties of null (reading 'parentNode')
        at parentNode (chunk-RAKT47ZN.js:9157:30)
        at ReactiveEffect.componentUpdateFn [as fn] (chunk-RAKT47ZN.js:7175:11)
        at ReactiveEffect.run (chunk-RAKT47ZN.js:423:19)
        at instance.update (chunk-RAKT47ZN.js:7212:52)
        at updateComponent (chunk-RAKT47ZN.js:7039:18)
        at processComponent (chunk-RAKT47ZN.js:6974:7)
        at patch (chunk-RAKT47ZN.js:6436:11)
        at patchKeyedChildren (chunk-RAKT47ZN.js:7363:9)
        at patchChildren (chunk-RAKT47ZN.js:7277:11)
        at processFragment (chunk-RAKT47ZN.js:6937:9)

    Because of this, I've modified the code inside CreateEditViewTask to call this function only if 
    env_state.value is undefined or empty.  That condition is the same as the condition used below.
    That change works.  I don't know why.

    The difference between CreateEditViewTask, and layout/default.vue, and SearchRunbookTextbox is: 
    CreateEditViewTask is rendered into the slot inside layout/default.vue where the 
    SearchRunbookTextbox component itself is not rendered into the slot inside layout/default.vue
    */
   
    let env_state = useEnvState();
    if ((typeof(env_state.value) == 'undefined') || (Object.keys(env_state.value).length === 0)) {
        let url = '/envs';
        if (typeof(window) == 'undefined') {
            url = 'http://localhost:3000' + url;
        } else {
            let loc = window.location;
            url = loc.protocol + '//' + loc.host + url;
        }
    
        const response = await logFetchAJAX(url, {});
        env_state.value = response;
    } 

    return env_state.value;
}