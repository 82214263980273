export const getCommunityURL =  () => {
    let community_url = "";

    if (! isAuthenticated()) {
        return "";
    }
  
    /*
    It is the responsiblity of the caller of this function to make sure that the settingStore is populated using:
    const settingStore = useSettingsStore();
    await settingStore.setSetting(null);

    The above code probably should only be called on the client-side only.
    */

    const settingStore = useSettingsStore();
    let settings = settingStore.getSetting;

    if (
      (settings.hasOwnProperty('admin_settings')) && 
      (settings.admin_settings.hasOwnProperty('flag_enable_dagknows_community')) &&
      (settings.admin_settings.flag_enable_dagknows_community) &&
      (settings.hasOwnProperty('dagknows_community_credentials')) &&
      (settings.dagknows_community_credentials.hasOwnProperty('url')) && 
      (settings.dagknows_community_credentials.url != '')
    ) {
        community_url = settings.dagknows_community_credentials.url;
    }
    return community_url;
}

