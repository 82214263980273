import Quill from 'quill';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';


var fonts = Quill.import('attributors/class/font');
fonts.whitelist = fontNames;
Quill.register(fonts, true);

var fontStyles = "";
fontList.forEach(function(font) {
  var fontName = getFontName(font);
  fontStyles += ".ql-snow .ql-picker.ql-font .ql-picker-label[data-value=" + fontName + "]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=" + fontName + "]::before {" +
      "content: '" + font + "';" +
      "font-family: '" + font + "', sans-serif;" +
      "}" +
      ".ql-font-" + fontName + "{" +
      " font-family: '" + font + "', sans-serif;" +
      "}";
});

export default defineNuxtPlugin((nuxtapp) => {
  nuxtapp.vueApp.component('QuillEditor', QuillEditor);

  var node = document.createElement('style');
  node.innerHTML = fontStyles;
  document.body.appendChild(node);

  // See the comment that I put inside our_jquery_dependent_code.js just above the getFontName function as well.
})