export const getEnforceLogin = async () => {

    const env = await getEnv();
    let enforce_login = env.enforce_login;
    
    if(enforce_login !== undefined){
        if (enforce_login.trim().toLowerCase() == "true") {
            return true;
        } else {
            return false;
        }
    }
}
