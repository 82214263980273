export const useUserInfoStore = defineStore("userInfoStore", {
    state: () => ({
        userInfo: {},
    }),
    getters: {
        getUserInfo() {
            return this.userInfo;
        },
    },
    actions: {
        setUserInfo(info) {
            this.userInfo = info
        },
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useUserInfoStore, import.meta.hot));
}